.react-image-shadow {
  position: relative;
  z-index: 0;
}
.react-image-shadow img {
  display: block;
  transition: all 0.4s ease;
}
.react-image-shadow-card {
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: -10%;
  left: 5%;
  width: 90%;
  height: 95%;
  z-index: -1;
  transition: all 0.4s;
}
.react-image-shadow-hover:hover img {
  transform: translateY(-6px);
}
.react-image-shadow-hover:hover .react-image-shadow-card {
  left: 10%;
  bottom: -20%;
  width: 80%;
}
